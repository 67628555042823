.nav__contents{
    padding: 0px 70px;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
    color:#FEFEFE;
    font-size: 18px;
    letter-spacing: 1px;
    text-shadow: 1px 2px black;
    /* padding-top: 20px; */
}
.nav__contents>h3{
    text-transform: uppercase;
    font-family: "Bremen-Bold",serif;
}
.nav__contents>h3:hover{
    cursor:pointer;
    color:rgb(231, 231, 231);
}
.logo{
    height:100px;
    width:100px;
}
.logo:hover{
    cursor: pointer;
}