.banner{
    margin-left: auto;
    margin-right: auto;
    display: block;
    left:0;
    right: 0;
    background: url("./banner.png") center no-repeat fixed;
    height:100vh;
    background-size: cover;
}
.banner__contents{
    margin-top: 5%;
}
.fname,.lname{
    font-family: "Bremen-Bold";
    color:#fff;
    font-size: 70px;
    letter-spacing: 3px;
    text-shadow: 2px 2px 8px #000;
    margin: 0;
}
.fname{
    margin-left: 17%;
}
.lname{
    margin-left: 24%;
}
.signature{
    font-family: "Autography";
    margin-left: 27%;
    font-size: 39px;
    letter-spacing: 3px;
    word-spacing: 10px;
    color:#fff;
    margin-top: 2px;
    text-shadow: 2px 2px 3px #000;
}