body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar{
  width:9px;
  border-left: 1px solid #E6ECF8; 
}
body::-webkit-scrollbar-thumb {
  background-color: #EABB94;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Bremen-Bold";
  src: local("Bremen-Bold"), url('./fonts/bremen-bold-bt.ttf') format("truetype");
}
@font-face {
  font-family: "Autography";
  src: local("Autography"), url('./fonts/Autography.otf') format("truetype");
}
