*{
    box-sizing: border-box;
}
.mywork{
    padding:40px;
    z-index: 1;
}
.mywork__contents>h1{
    text-align: center;
    font-family: "Bremen-Bold",serif;
    font-size: 60px;
    color:#8A733E;
    letter-spacing: 2px;
    margin-bottom: 20px;
}
.lines{
    width:100%;
    height:45px;
    position: relative;
}
.lines::after,.lines::before{
    content:'';
    position: absolute;
    margin: auto;
    height:2px;
    background: #8A733E;
    width:40%;
    top:45%;
}
.lines::after{
    left:0;
}
.lines::before{
    right:0;
}
.circle{
    height:40px;
    width:40px;
    border: 2px solid #8A733E;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin: auto;
    border-radius: 50%;
}
.mywork__contentsMarquee{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}
.track>img{
    height:300px;
    width:350px;
    margin-right: 40px;
    border-radius: 20px;
}
.track{
    padding-bottom: 60px;
}
.track>img:hover{
    cursor: pointer;
}
.mywork__btn{
    font-size: 25px;
    padding: 10px 40px;
    background-color: transparent;
    color:#8E8463;
    border:0.5px solid #8E8463;
    outline:none;
    display: block;
    letter-spacing: 1px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.mywork__btn:hover{
    cursor: pointer;
    color:#FFF5DF;
    background-color: #8A733E;
}